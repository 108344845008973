import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgHash from 'lightgallery/plugins/hash';
import lgShare from 'lightgallery/plugins/share';

export default {
  init() {
    // thumbnail gallery
    lightGallery(document.getElementById('locationGallery'), {
        plugins: [lgZoom, lgThumbnail, lgHash, lgShare],
        thumbnail:true,
    });
    // reviews slider
    //reviews slider
    if($('.location-reviews-slider')[0]) {
      $('.location-reviews-slider').slick({
        dots: false,
        infinite: true,
        arrows: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows:0,
        autoplay:false,
        nextArrow: '<div class="arrow-right primary-color"><i class="far fa-chevron-right"></i></div>',
        prevArrow: '<div class="arrow-left primary-color"><i class="far fa-chevron-left"></i></div>',
        adaptiveHeight:true,
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
