import AOS from 'aos';
import 'slick-carousel';
import 'jquery.mb.ytplayer';
import 'lightgallery';

export default {
  init() {
    // JavaScript to be fired on all pages
    AOS.init({
      disable: 'mobile',
      duration: 600,
      once: true,
    });
    // start sliders
    if ($('.card-slider')[0]) {
      $('.card-slider').slick({
        dots: false,
        infinite: true,
        nextArrow: '<div class="arrow-right"><i class="far fa-chevron-right"></i></div>',
        prevArrow: '<div class="arrow-left"><i class="far fa-chevron-left"></i></div>',
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [{
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }],
      });
    }
    if ($('.slick-logo-banner')[0]) {
      $('.slick-logo-banner').slick({
        dots: true,
        infinite: true,
        speed: 500,
        cssEase: 'linear',
        swipeToSlide: 'true',
        autoplay: true,
        pauseOnHover: true,
        pauseOnFocus: false,
        autoplaySpeed: 5000,
        nextArrow: '<div class="arrow-right"><i class="far fa-chevron-right"></i></div>',
        prevArrow: '<div class="arrow-left"><i class="far fa-chevron-left"></i></div>',
        arrows: true,
        slidesToShow: 5,
        adaptiveHeight: false,
        slidesToScroll: 1,
        responsive: [{
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
          },
        }, {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        }],
      });
    }
    /** hero slider **/
    $('.hero-bkg-slider, .absolute-slider').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1500,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplaySpeed: 5000,
    });
    //reviews slider
    if ($('.reviews-slick')[0]) {
      $('.reviews-slick').slick({
        dots: false,
        infinite: true,
        arrows: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        nextArrow: '<div class="arrow-right"><i class="far fa-chevron-right"></i></div>',
        prevArrow: '<div class="arrow-left"><i class="far fa-chevron-left"></i></div>',
        adaptiveHeight: true,
        responsive: [{
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }],
      });
    }
    // end sliders

    // refresh AOS
    AOS.refresh();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};