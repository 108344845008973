// import external dependencies
import 'jquery';
import AOS from 'aos';
AOS.init({
  disable: 'mobile',
  duration: 600,
  once: true,
});
import 'slick-carousel';
import 'masonry-layout';
//import 'jquery.mb.ytplayer';

// Font Awesome Config
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPrint, faChevronLeft, faClock, faAddressCard, faFireAlt, faFire, faSnowflake, faWind, faAirConditioner, faLeaf, faBuilding, faShower, faTools, faCannabis, faCookieBite, faEyeDropper, faJoint, faPumpSoap, faFlask, faMapMarkerAlt, faStar, faArrowRight, faArrowLeft, faPhone, faTags, faUserTie, faMapMarkedAlt, faSortDown, faFileDownload, faShoppingCart, faStore, faComments, faEnvelopeOpen, faQuoteRight, faFilter, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import { faFacebookSquare, faInstagram, faTwitterSquare, faFacebook, faTwitter, faGoogle, faYelp, faMicrosoft, faLinkedin, faPinterest, faYoutube, faVimeo, faSnapchat } from '@fortawesome/free-brands-svg-icons';

// Icons with multiple styles
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight as fasChevronRight, faUser } from '@fortawesome/pro-solid-svg-icons';

//add icons to lib
library.add(farChevronRight, fasChevronRight, faChevronLeft, faClock, faAddressCard, faFireAlt, faFire, faSnowflake, faWind, faAirConditioner, faLeaf, faBuilding, faShower, faTools, faCannabis, faCookieBite, faEyeDropper, faJoint, faPumpSoap, faFlask, faMapMarkerAlt, faStar, faArrowRight, faArrowLeft, faPhone, faUser, faTags, faPrint, faUserTie, faMapMarkedAlt, faSortDown, faFileDownload, faShoppingCart, faStore, faComments, faFacebookSquare, faTwitterSquare, faFacebook, faTwitter, faInstagram, faGoogle, faYelp, faBuilding,faEnvelopeOpen,faMicrosoft,faLinkedin,faPinterest,faYoutube,faVimeo,faSnapchat,faQuoteRight,faFilter,faPencilAlt);

dom.watch();

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import singleLocation from './routes/location';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // single location
  singleLocation,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
